import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BasicPage from "../templates/BasicPage"
import IndexHero from "../components/index/IndexHero"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="" description="" />
      <div
        css={{
          width: "100%",
          height: "100%",
          backgroundColor: "#F95650",
        }}
      >
        <IndexHero />
      </div>
    </Layout>
  )
}

export default IndexPage
