const breakpoints: { [index: string]: number } = {
  sm: 500,
  md: 600,
  lg: 835,
  xl: 1136,
}

const mq = Object.keys(breakpoints)
  .map(key => [key, breakpoints[key]] as [string, number])
  .reduce((prev, [key, breakpoint]) => {
    prev[key] = `@media (min-width: ${breakpoint}px)`
    return prev
  }, {} as { [index: string]: string })

export default mq
