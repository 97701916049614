/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react"
import { Global, css, ThemeProvider } from "@emotion/react"

import roboto from "../assets/Roboto-Light.ttf"
import { defaultTheme } from "../emotion/defaultTheme"

import "normalize.css/normalize.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Global
        styles={css`
          @font-face {
            font-family: Roboto;
            font-weight: 300;
            src: url(${roboto}) format("truetype");
          }
          * {
            font-family: Roboto;
            font-weight: 300;
          }
          a {
            text-decoration: none;
          }
        `}
      />
      {children}
    </ThemeProvider>
  )
}

export default layout
