import * as React from "react"
import { useEffect, useRef, useState } from "react"
import Img from "gatsby-image"
import { graphql, Link, useStaticQuery } from "gatsby"
import Slider from "react-slick"

import mq from "../../emotion/mq"
import signature from "./assets/signature.svg"
import background from "../../images/PhotoCollage.png"
import mobileBackground from "../../images/MobileBackground.png"
import mobileBackground2 from "../../images/MobileImage2.png"
import mobileBackground3 from "../../images/MobileImage3.png"

const imageQuery = () =>
  useStaticQuery(graphql`
    query {
      collage: file(relativePath: { eq: "PhotoCollage.png" }) {
        childImageSharp {
          fluid(maxWidth: 969) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      logo: file(relativePath: { eq: "VentouraLogo.png" }) {
        childImageSharp {
          fixed(width: 159) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

const sliderSettings = {
  dots: false,
  infinte: true,
  speed: 7000,
  arrows: false,
  autoplay: true,
  draggable: false,
  fade: true,
  slidesToShow: 1,
  swipe: false,
  adaptiveHeight: true,
}

const Hero = () => {
  const images = imageQuery()
  return (
    <div
      css={{
        position: "relative",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        [mq["lg"]]: {
          justifyContent: "flex-start",
        },
        [mq["xl"]]: {
          justifyContent: "flex-end",
        },
      }}
    >
      <div
        css={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: 5,
          [mq["md"]]: {
            alignItems: "flex-start",
            maxWidth: 400,
            marginRight: 0,
            marginLeft: "0",
            marginTop: 196,
            justifyContent: "normal",
          },
          [mq["lg"]]: {
            marginLeft: "10%",
          },
          [mq["xl"]]: {
            marginRight: "60%",
          },
        }}
      >
        <div
          css={{
            display: "block",
            marginTop: 48,
            [mq["md"]]: {
              display: "none",
            },
          }}
        >
          <Img fixed={images.logo.childImageSharp.fixed} />
        </div>
        <div
          css={{
            display: "none",
            [mq["md"]]: {
              display: "block",
            },
          }}
        >
          <Img fixed={images.logo.childImageSharp.fixed} />
        </div>
        <div
          css={{
            background: "rgba(31, 34, 38, 0.6)",
            padding: "28px 0",
            [mq["md"]]: {
              background: "none",
            },
          }}
        >
          <p
            css={{
              marginLeft: "auto",
              marginRight: "auto",
              color: "white",
              fontWeight: 300,
              fontSize: 16,
              width: "90%",
              [mq["md"]]: {
                width: "100%",
              },
            }}
          >
            Thanks for the memories! We're ever grateful to our community and
            tireless Ventoura locals who created amazing experiences for
            travellers across Europe.
          </p>
          <p
            css={{
              marginLeft: "auto",
              marginRight: "auto",
              color: "white",
              fontWeight: 300,
              fontSize: 16,
              width: "90%",
              marginBottom: 49,
              [mq["md"]]: {
                marginBottom: 30,
                width: "100%",
              },
            }}
          >
            For your next adventure check out{" "}
            <a
              css={{ textDecoration: "underline", color: "white" }}
              href="https://www.airbnb.com/experiences"
              target="_blank"
              rel="noopener noreferrer"
            >
              Airbnb Experiences
            </a>
            .
          </p>
          <img
            css={{
              marginLeft: 45,
              float: "right",
              marginRight: "5%",
              [mq["md"]]: {
                float: "left",
                marginRight: 0,
              },
            }}
            src={signature}
            alt=""
          />
        </div>
      </div>
      <div
        css={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
          height: "100vh",
          backgroundColor: "#000",
          backgroundSize: "cover",
          opacity: 1,
          [mq["md"]]: {
            opacity: 0.2,
            backgroundColor: "rgba(0,0,0,0)",
            backgroundPosition: "100% 0",
            backgroundImage: `url(${background})`,
          },
          [mq["xl"]]: {
            opacity: 1,
          },
        }}
      >
        <Slider
          css={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
            [mq["md"]]: {
              display: "none",
            },
          }}
          {...sliderSettings}
        >
          <div
            css={{
              width: "100%",
              height: "100vh",
              backgroundImage: `url(${mobileBackground})`,
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          ></div>
          <div
            css={{
              width: "100%",
              height: "100vh",
              backgroundImage: `url(${mobileBackground2})`,
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          ></div>
          <div
            css={{
              width: "100%",
              height: "100vh",
              backgroundImage: `url(${mobileBackground3})`,
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          ></div>
        </Slider>
      </div>
    </div>
  )
}

export default Hero
